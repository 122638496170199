$.fn.extend({
  dynamicSelectable: function() {
    $(this).each(function(i, el) {
      new DynamicSelectable($(el))
    });
  }
});

class DynamicSelectable {
  constructor($select) {
    this.init($select)
  }

  init($select) {
    this.blankText = $select.data('dynamicSelectableBlank')
    this.urlTemplate = $select.data('dynamicSelectableUrl')
    this.$targetSelect = $($select.data('dynamicSelectableTarget'))

    $select.on('change', function() {
      this.clearTarget()
      var url = this.constructUrl($select.val())
      if (url) {
        $.getJSON(url, function(data) {
          $.each(data, function(index, el) {
            if (el.name === 'CALZADO') {
              this.$targetSelect.append(`<option value='${el.id}'>CALZADO (Solo nuevos sin uso)</option>`)
            } else if (el.name === 'TRAJES DE BAÑO') {
              this.$targetSelect.append(`<option value='${el.id}'>TRAJES DE BAÑO (Solo nuevos sin uso)</option>`)
            } else if (el.name === 'VESTIDO DE NOVIA') {
              this.$targetSelect.append(`<option value='${el.id}'>VESTIDO DE NOVIA (Tintorería reciente)</option>`)
            } else {
              this.$targetSelect.append(`<option value='${el.id}'>${el.name}</option>`)
            }
          }.bind(this))
          this.reinitializeTarget()
        }.bind(this))
      } else {
        this.reinitializeTarget()
      }
    }.bind(this))
  }

  reinitializeTarget() {
    this.$targetSelect.trigger("change")
  }

  clearTarget() {
    this.$targetSelect.html(`<option>${this.blankText}</option>`)
  }

  constructUrl(id) {
    if (id && id != '') {
      return this.urlTemplate.replace(/:.+_id/, id)
    }
  }
}
