// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "packs/dynamic-selectable"
// While inventory is implemented
import { Html5QrcodeScanner } from "html5-qrcode"
import select2 from 'select2'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "materialize-css/dist/js/materialize"

// Works with Javascript
// document.addEventListener('DOMContentLoaded', function() {
//   M.AutoInit();
// });

// Works with JQuery
// $(document).on('ready page:load turbolinks:load', () => {
//   M.AutoInit()
// })

$(document).on('turbolinks:before-visit turbolinks:before-cache', () => {
  var sidenavElems = document.querySelectorAll('.sidenav')
  sidenavElems.forEach((element, index, array) => {
    if (element) { var instance = M.Sidenav.getInstance(element) }
    if (instance) {
      if (instance.isOpen) { instance.close() } // close on click
      instance.destroy()
    }
  })

  var selectElems = document.querySelectorAll('select.select-single')
  selectElems.forEach((element, index, array) => {
    if (element) { var instance = M.FormSelect.getInstance(element) }
    if (instance) {
      instance.destroy()
    }
  })
})

$(document).on('ready page:load turbolinks:load', () => {
  var carouselWIElems = document.querySelectorAll('.carousel-w-indicators')
  if (carouselWIElems) { var instances = M.Carousel.init(carouselWIElems, { fullWidth: true, indicators: true }) }

  var sidenavElems = document.querySelectorAll('.sidenav')
  if (sidenavElems) { var instances = M.Sidenav.init(sidenavElems, {}) }

  $('.parallax').parallax();

  $('.how').click(() => {
    $('html, body').animate({ scrollTop: $("#how").offset().top -= 80 }, 800)
  })

  $('.contact-us').click(() => {
    $('html, body').animate({ scrollTop: $("#contact-us").offset().top -= 75 }, 800)
  })

  $('.dropdown-trigger-header').dropdown()

  var collapsibleElems = document.querySelectorAll('.collapsible')
  if (collapsibleElems) { var instances = M.Collapsible.init(collapsibleElems, {}) }

  var selectElems = document.querySelectorAll('select.select-single')
  if (selectElems) { var instances = M.FormSelect.init(selectElems, {}) }

  $('select.select-w-search').select2()

  let brandInput = $('#product_brand_id')
  let proposedBrandBox = $('.proposed-brand')
  let proposedBrandInput = $('#product_proposed_brand')

  brandInput.on('change', function(event) {
    // ID 909 = 'OTRA'
    if (event.target.value == '909') {
      proposedBrandBox.show();
      proposedBrandInput.attr('required', true);
    } else {
      proposedBrandInput.val('');
      proposedBrandInput.removeAttr('required');
      proposedBrandBox.hide();
    }
  });

  let categoryInput = $('#product_category_id')
  let proposedPriceBox = $('.proposed-price')
  let proposedPriceInput = $('#product_proposed_price')
  let preimageBox = $('.btn-file-upload')
  let departmentInput = $('#department_kind')

  categoryInput.on('change', function(event) {
    if (event.target.value == '78') { // ID 78 = 'VESTIDO DE NOVIA'
      proposedPriceBox.show();
      preimageBox.css('display', 'flex');
      proposedPriceInput.attr('required', true);
    } else if (departmentInput.val() == 'deco') { // 'DECO'
      preimageBox.css('display', 'flex');
      proposedPriceInput.val(null);
      proposedPriceInput.removeAttr('required');
      proposedPriceBox.hide();
    } else {
      proposedPriceInput.val(null);
      $('#upload_logo').val('');
      $('#uploadFile_logo').val('');
      $('#product_image').val('');
      proposedPriceInput.removeAttr('required');
      proposedPriceBox.hide();
      preimageBox.hide();
    }
  });

  departmentInput.on('change', function(event) {
    if (event.target.value == 'deco') { // 'DECO'
      preimageBox.css('display', 'flex');
      proposedPriceInput.val(null);
      proposedPriceInput.removeAttr('required');
      proposedPriceBox.hide();
    } else if (categoryInput.val() == '78') { // ID 78 = 'VESTIDO DE NOVIA'
      proposedPriceBox.show();
      preimageBox.css('display', 'flex');
      proposedPriceInput.attr('required', true);
    } else {
      proposedPriceInput.val(null);
      $('#upload_logo').val('');
      $('#uploadFile_logo').val('');
      $('#product_image').val('');
      proposedPriceInput.removeAttr('required');
      proposedPriceBox.hide();
      preimageBox.hide();
    }
  });

  var modalElems = document.querySelectorAll('.modal')
  if (modalElems) { var instances = M.Modal.init(modalElems, {}) }

  var toolTipElems = document.querySelectorAll('.tooltipped')
  if (toolTipElems) { var instances = M.Tooltip.init(toolTipElems, {}) }

  var autocompleteElems = document.querySelectorAll('.autocomplete');
  if (autocompleteElems) {
    var instances = M.Autocomplete.init(autocompleteElems, {
      data: {
        "AMIGO": null,
        "ARTUROPRATCHACON": null,
        "AUTORIZA_MIC": null,
        "BIENVENIDA": null,
        "BIRTHDAY": null,
        "CANTIDAD": null,
        "COMPRADOR": null,
        "CONSIGNATARIO": null,
        "EMPLEADO": null,
        "FAMILIA": null,
        "MONTO": null,
        "PRODUCTO": null,
        "ROCKSTAR": null,
        "TEEXTRAÑAMOS10": null,
        "TEEXTRAÑAMOS15": null,
        "TEEXTRAÑAMOS20": null,
        "5_OFF": null
      }
    });
  }

  // DELETE $('#product_offer_category_id').on('change', function() {
  //   M.FormSelect.init(this, {})
  // });

  // $('#product_category_id').on('change', function() {
  //   M.FormSelect.init(this, {})
  // });

  $('#pick_up_address_region').on('change', function() {
    M.FormSelect.init(this, {})
  });

  $('#pick_up_address_commune').on('change', function() {
    M.FormSelect.init(this, {})
  });

  $('#customer_info_address_state').on('change', function() {
    M.FormSelect.init(this, {})
  });

  $('#customer_info_address_city').on('change', function() {
    M.FormSelect.init(this, {})
  });

  $('select.select-single[data-dynamic-selectable-url][data-dynamic-selectable-target]').dynamicSelectable()
  $('select.select-w-search[data-dynamic-selectable-url][data-dynamic-selectable-target]').dynamicSelectable()

  let maxDate = new Date(new Date().getFullYear() - 15, new Date().getMonth(), new Date().getDay())
  let firstYear = maxDate.getFullYear() - 70
  let lastYear = maxDate.getFullYear()

  var datepickerElems = document.querySelectorAll('.datepicker')
  if (datepickerElems) {
    var instances = M.Datepicker.init(datepickerElems, {
      format: 'dd-mm-yyyy',
      defaultDate: maxDate,
      maxDate: maxDate,
      yearRange: [firstYear, lastYear]
    })
  }

  $('.sell-with-us-old').click((e) => {
    // var springModal = $('#spring-modal');
    // var instance = M.Modal.getInstance(springModal);
    var springModal = $('#pickup-schedule-modal');
    var instance = M.Modal.getInstance(springModal);

    event.preventDefault();

    instance.open();
  });

  $('.post-drop-in-store').click((e) => {
    // var springModal = $('#spring-modal');
    // var instance = M.Modal.getInstance(springModal);
    var springModal = $('#post-drop-in-store-modal');
    var instance = M.Modal.getInstance(springModal);
  
    event.preventDefault();
  
    instance.open();
  });

  $('.post-is-region').click((e) => {
    // var springModal = $('#spring-modal');
    // var instance = M.Modal.getInstance(springModal);
    var springModal = $('#post-is-region-modal');
    var instance = M.Modal.getInstance(springModal);
  
    event.preventDefault();
  
    instance.open();
  });
  
  $('.post-no-region').click((e) => {
    // var springModal = $('#spring-modal');
    // var instance = M.Modal.getInstance(springModal);
    var springModal = $('#post-no-region-modal');
    var instance = M.Modal.getInstance(springModal);
  
    event.preventDefault();
  
    instance.open();
  });

  $('.rdr-return-products').click((e) => {
    var rdrReturnModal = $('#rdr-return-modal');
    var instance = M.Modal.getInstance(rdrReturnModal);

    event.preventDefault();

    instance.open();
  });

  $('.rdr-donate-products').click((e) => {
    var rdrDonateModal = $('#rdr-donate-modal');
    var instance = M.Modal.getInstance(rdrDonateModal);

    event.preventDefault();

    instance.open();
  });

  $('.brand-vat').click((e) => {
    var brandVatModal = $('#brand-vat-modal');
    var instance = M.Modal.getInstance(brandVatModal);

    event.preventDefault();

    instance.open();
  });

  $('.sell-with-us-close-modal').click((e) => {
    // var springModal = $('#spring-modal');
    // var instance = M.Modal.getInstance(springModal);
    var springModal = $('#provisory-consignment-reminder-modal');
    var instance = M.Modal.getInstance(springModal);

    event.preventDefault();

    instance.close();
  });

  $('.pickup-communes-reminder').click((e) => {
    var pickupCommunesReminderModal = $('#pickup-communes-reminder-modal');
    var instance = M.Modal.getInstance(pickupCommunesReminderModal);
    $(this).prop('checked', true);
    instance.open();
  });

  $('.pickup-communes').click((e) => {
    var pickupCommunesModal = $('#pickup-communes-modal');
    var instance = M.Modal.getInstance(pickupCommunesModal);
    instance.open();
  });

  // Excelente código para mostrar popups con un parametro en la URL
  let urlString = window.location.href;
  let paramString = urlString.split('?')[1];
  let queryString = new URLSearchParams(paramString);

  if (queryString.size != 0) {
    for (let pair of queryString.entries()) {
      if (pair[0] == 'show_modal' && pair[1] == 'pcr') {
        var provisoryModal = $('#provisory-consignment-reminder-modal');
        var instance = M.Modal.getInstance(provisoryModal);

        instance.open();
      }
      if (pair[0] == 'show_modal' && pair[1] == 'bv') {
        var brandVatModal = $('#brand-vat-modal');
        var instance = M.Modal.getInstance(brandVatModal);

        instance.open();
      }
    }
  }
  // Evento para girar tarjetas
  const wimpcards = document.querySelectorAll('.flipp-card');
  wimpcards.forEach(wimpcard => {
    wimpcard.addEventListener('click', function() {
      if (this.classList.contains('flipped')) {
        this.classList.remove('flipped');
      } else {
        this.classList.add('flipped');
      }
    });
  });
})

$(document).on('ready page:load turbolinks:load', () => {
  // While inventory is implemented
  $('.scan-qrcode').click((e) => {
    var orderDiscountModal = $('#scan-qrcode-modal');
    var instance = M.Modal.getInstance(orderDiscountModal);

    event.preventDefault();

    instance.open();
  });

  $('.scan-qrcode-close-modal').click((e) => {
    var orderDiscountModal = $('#scan-qrcode-modal');
    var instance = M.Modal.getInstance(orderDiscountModal);

    event.preventDefault();

    instance.close();
  });

  var lastResult, countResults = 0;
  function onScanSuccess(decodedText, decodedResult) {
    if (decodedText !== lastResult) {
      ++countResults;
      lastResult = decodedText;
      // Handle on success condition with the decoded message.
      console.log(`Scan result ${decodedText}`, decodedResult);

      let eanInput = $('#item_ean_or_sku');
      eanInput.val(decodedText);

      let eanForm = $('#new_item');
      eanForm.trigger("submit");
    }
  }

  $('.order-discount').click((e) => {
    var orderDiscountModal = $('#order-discount-modal');
    var instance = M.Modal.getInstance(orderDiscountModal);

    event.preventDefault();

    instance.open();
  });

  $('.order-discount-close-modal').click((e) => {
    var orderDiscountModal = $('#order-discount-modal');
    var instance = M.Modal.getInstance(orderDiscountModal);

    event.preventDefault();

    instance.close();
  });

  $('.pickup-communes-reminder').click((e) => {
    var pickupCommunesReminderModal = $('#pickup-communes-reminder-modal');
    var instance = M.Modal.getInstance(pickupCommunesReminderModal);
    $(this).prop('checked', true);
    instance.open();
  });

  $('.pickup-communes').click((e) => {
    var pickupCommunesModal = $('#pickup-communes-modal');
    var instance = M.Modal.getInstance(pickupCommunesModal);
    instance.open();
  });

  // Excelente código para mostrar popups con un parametro en la URL
  let urlString = window.location.href;
  let paramString = urlString.split('?')[1];
  let queryString = new URLSearchParams(paramString);

  if (queryString.size != 0) {
    for (let pair of queryString.entries()) {
      if (pair[0] == 'show_modal' && pair[1] == 'pcr') {
        var provisoryModal = $('#provisory-consignment-reminder-modal');
        var instance = M.Modal.getInstance(provisoryModal);

        instance.open();
      }
      if (pair[0] == 'show_modal' && pair[1] == 'bv') {
        var brandVatModal = $('#brand-vat-modal');
        var instance = M.Modal.getInstance(brandVatModal);

        instance.open();
      }
    }
  }
})

$(document).on('ready page:load turbolinks:load', () => {
  // While inventory is implemented
  $('.scan-qrcode').click((e) => {
    var orderDiscountModal = $('#scan-qrcode-modal');
    var instance = M.Modal.getInstance(orderDiscountModal);

    event.preventDefault();

    instance.open();
  });

  $('.scan-qrcode-close-modal').click((e) => {
    var orderDiscountModal = $('#scan-qrcode-modal');
    var instance = M.Modal.getInstance(orderDiscountModal);

    event.preventDefault();

    instance.close();
  });

  var lastResult, countResults = 0;
  function onScanSuccess(decodedText, decodedResult) {
    if (decodedText !== lastResult) {
      ++countResults;
      lastResult = decodedText;
      // Handle on success condition with the decoded message.
      console.log(`Scan result ${decodedText}`, decodedResult);

      let eanInput = $('#item_ean_or_sku');
      eanInput.val(decodedText);

      let eanForm = $('#new_item');
      eanForm.trigger("submit");
    }
  }

  if (document.getElementById('qr-reader') !== null) {
    var html5QrcodeScanner = new Html5QrcodeScanner("qr-reader", { fps: 10, qrbox: 250 });
    html5QrcodeScanner.render(onScanSuccess);
  }
})
